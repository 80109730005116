.trigger {
  padding: 0 24px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.breadcrumb {
  margin: 16px 0;
  font-size: 13px;
  padding: 0 24px 0px 24px;
}

.logo {
  height: 32px;
  margin: 16px;
  text-align: center;
  /* background: rgba(255, 255, 255, 0.3); */
}

.company-name {
  visibility: hidden;
  animation: appearCompanyName 0s 0.15s forwards;
}

@keyframes appearCompanyName {
  to {
    visibility: visible;
  }
}

.site-layout .site-layout-background {
  background: #f0f2f5;
  /*background: #ffffff;*/
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.1);
  padding: 0;
}

.site-layout .site-layout-background.header {
  background: #ffffff;
}

.custom-bg-header {
  background: #ffffff;
}

.site-layout .ant-avatar {
  width: 24px;
  height: 24px;
}

.ant-layout-has-sider {
  min-height: 100vh;
}

.title {
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.85);
  background: white;
}

.reservation-tabs .ant-tabs-tab {
  font-size: 12px;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
  padding-left: 24px;
}

.content {
  background-color: #e5e5e5;
  padding: 24px 24px 24px 24px;
}

.ant-card-head {
  background: #fafafa;
  box-shadow: inset 0px -1px 0px #e8e8e8;
  border-radius: 2px;
  border-bottom: none;
}

.ant-card-head .ant-card-head-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: bold;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1d39c4;
  border-color: #1d39c4;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1d39c4;
}

.ant-tabs-tab:hover,
.ant-picker-today-btn,
.ant-picker-header-view button:hover {
  color: #1d39c4;
}

.ant-select-selector:hover,
textarea:hover,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1d39c4 !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #1d39c4;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #1d39c4;
}

.ant-tabs-ink-bar {
  background: #1d39c4;
}

.ant-checkbox {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-sizing: border-box;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #1d39c4 !important;
  border-color: #1d39c4 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1d39c4;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1d39c4 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #1d39c4;
}

.ant-modal-footer > .ant-btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5 !important;
}

.tabs-cart > .ant-tabs-nav {
  background: #fafafa;
}

/* Custome All Table CSS */
.ant-table-tbody {
  color: rgba(0, 0, 0, 0.65);
}

.ant-table-thead > tr > th::before {
  display: none;
}

/* End Custome All Table CSS */

/* Pagination CSS */
.ant-pagination > .ant-pagination-item,
.ant-pagination > .ant-pagination-prev > button,
.ant-pagination > .ant-pagination-next > button {
  border-radius: 6px;
}

.ant-pagination > .ant-pagination-item:hover,
.ant-pagination > .ant-pagination-prev > button:hover,
.ant-pagination > .ant-pagination-next > button:hover {
  border-color: #1d39c4;
}

.ant-pagination-item a:hover {
  color: #1d39c4;
}

.ant-pagination > .ant-pagination-item-active {
  background: #1d39c4;
  border-color: #1d39c4;
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
  color: #ffffff;
}
/* End Pagination CSS */

.card-calendar-notes .ant-card-body {
  padding: 0px;
}

.card-calendar-notes .ant-card-head {
  min-height: 31px;
  background-color: #dce2ea;
  padding-left: 12px;
}

.card-calendar-notes .ant-card-head .ant-card-head-title {
  padding: 8px 0;
}

/* Hide FullCalendar License */
.fc-license-message {
  display: none;
}

/* For Mobile View */
@media screen and (max-device-width: 640px) {
  .trigger {
    padding: 0 12px;
  }
  .header {
    position: relative;
    height: 100px !important;
  }
  .header-branch-name {
    margin-left: 8% !important;
  }
  .header-logo {
    position: absolute;
    top: 10px;
    right: 2%;
  }
  .header-user-name {
    display: none;
  }
  .notification-badge {
    position: absolute;
    top: 1px;
    right: 15%;
  }
  .notification-badge .ant-badge-count {
    right: 19px;
  }
}
/* For Mobile Landscape View */
@media screen and (max-device-width: 900px) and (orientation: landscape) {
  .header-branch-name {
    margin-left: 2% !important;
  }
  .header-info .system-date {
    margin-right: 15 !important;
  }
  .notification-badge .ant-badge-count {
    right: 19px;
  }
}

.ant-layout-sider-zero-width-trigger {
  background-color: white;
  color: black;
  top: 5px;
  right: -50px;
}
