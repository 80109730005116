.modal-room-detail .ant-modal-title {
  font-weight: 600;
}
.modal-room-detail .ant-modal-body {
  padding: 0 !important;
  height: 600px;
  overflow-x: scroll;
}
.modal-reservation-information .ant-modal-body {
  padding: 0 !important;
}
.reservation-information_span {
  color: rgba(0, 0, 0, 0.25);
}
.reservation-information_span-right {
  float: right;
}
.modal-reservation-information .ant-modal-title {
  font-weight: 600;
}
.modal-row {
  background: 'white';
  width: '90%';
  text-align: 'center';
  margin-left: '5%';
  margin-top: '5vh';
  background-color: 'rgba(240, 242, 245, 1)';
  padding: '24px';
  margin: 0;
}
.span-blur {
  color: rgba(0, 0, 0, 0.25) !important;
}
.select-room-card .ant-card-body {
  padding-top: 8px;
  padding-bottom: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.room-list .ant-card-body {
  padding-top: 24px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.pt-15 {
  padding-top: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
.span-header {
  font-size: 16px;
  font-weight: 700;
}
.span-header-right {
  padding-left: 1vh;
}
.modal-reservation-info .ant-modal-body {
  background-color: #f0f2f5;
}
.border-box {
  --b: 4px; /* thickness of the border */
  --w: 16px; /* width of border */
  --r: 25px; /* radius */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);

  /*Irrelevant code*/
  width: 85%;
  height: 160px;
  box-sizing: border-box;
  margin: 5px;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.border-box_active {
  --c: rgba(24, 144, 255, 1); /* color of the border */
}

.border-box_non-active {
  --c: #bbb;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3%;
}
.circle_non-active {
  background-color: #bbb;
}
.circle_active {
  background-color: rgba(24, 144, 255, 1);
}
.camera-input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  width: 98%;
}
.radio-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14;
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
}
.radio-border {
  width: 100%;
  padding-bottom: 2%;
  padding-top: 2%;
}
.ic-image {
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 7%;
  padding-right: 7%;
  height: auto;
}
.circle_delete {
  background-color: rgba(255, 120, 117, 1);
}
.ic-group-btn {
  padding-bottom: 1%;
  padding-top: 2%;
}
.mr-3 {
  margin-right: 3%;
}
