.schedule-calendar .ant-picker {
  visibility: hidden;
  position: absolute;
  left: 41%;
}

.schedule-calendar .fc-view-harness,
.schedule-tab .fc-view-harness {
  max-height: 500px;
}

.title-date {
  font-size: 18px;
  color: #1d39c4;
  font-weight: 600;
  cursor: pointer;
}

.schedule-calendar .fc-header-toolbar {
  margin-bottom: -20px !important;
}
