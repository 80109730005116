.transaction-checkout .ant-card-head {
  box-shadow: none;
  border-bottom: 1px solid #1d39c4 !important;
  background: #ffffff;
}

.transaction-checkout .ant-card-head-title {
  text-align: center;
  color: #1d39c4 !important;
}

.transaction-checkout .ant-card-grid {
  box-shadow: none;
  padding: 10px;
}

.transaction-checkout .ant-card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 85%;
}

.transaction-tabs .ant-card-body {
  display: flex;
  flex-direction: column;
  min-height: 540px;
}

.transaction-tabs .ant-table-wrapper {
  flex-grow: 1;
}

.transaction-tabs .ant-tabs-nav-wrap {
  margin-left: -15px;
}

.transaction-tabs .ant-card-head {
  box-shadow: none;
}

.transaction-tabs .ant-tabs-tab-btn {
  font-size: 14px;
}

.btn-checkout-icon {
  background-color: transparent !important;
  border: none !important;
}
