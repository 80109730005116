.customer-detail-tag {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.customer-detail-tag span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #303F9F;
  flex: none;
  padding: 5px 12px;
  gap: 10px;
  border: 1px solid #303F9F;
  border-radius: 2px;
}

.customer-detail-tab .ant-tabs-nav {
  background: #FAFAFA;
  box-shadow: inset 0 -1px 0 #E8E8E8;
}

.customer-detail-about .ant-card-head-title {
  font-weight: normal !important;
  padding: 12px 0 !important;
}

.customer-detail-about .ant-card-body {
  padding: 0 !important;
}
