.data-channel {
  padding: 0 !important;
}

.green > .ant-table-cell:first-child {
  background: rgba(29, 57, 196, 0.1);
}

.green > .ant-table-cell:nth-child(2) .ant-select-selector {
  border: none;
  margin-left: -15px;
  font-size: 12px;
  width: 99px;
  color: #1d39c4;
  font-size: 12px;
  font-weight: 600;
}

.green > .ant-table-cell:nth-child(2) {
  width: 130px;
  padding: 0px;
  padding-left: 16px;
}

.green .ant-select-selection-item {
  font-weight: 600;
  color: #1d39c4;
}

.search-room-rate input {
  font-size: 12px;
}

.search-room-rate .ant-input-search-button {
  background: #f0f2f5;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.search-room-rate .ant-input-search-button:hover {
  background: #f0f2f5;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.search-room-rate .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.search-room-rate > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 4px 4px 0;
}

.pick-date .ant-picker > .ant-picker-input > input {
  font-size: 16px;
  color: #1d39c4;
  font-weight: 600;
}

.channel-table .ant-table-thead > tr > th {
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.loading-date {
  height: 100% !important;
}
